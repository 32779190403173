<template>
  <section id="user-list">
    <b-breadcrumb
      :items="breadcrumb"
    />

    <b-row>
      <b-col cols="12">
        <b-card-actions
          ref="filters"
          title="Filtros"
          class="form-filters"
          :action-close="false"
          :action-refresh="false"
          :action-collapse="true"
          @refresh="refreshStop('cardAction')"
        >
          <b-row>
            <b-col cols="3">
              <b-form-group
                label="Nome / E-mail / Telefone / Id"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="filtersValues.name_or_email"
                  @keyup.enter="getUsers"
                  @keyup="refreshNameFilter"
                />
                <small class="d-block text-right">{{ applyFilterIn }}</small>
              </b-form-group>
            </b-col>

            <b-col cols="3">
              <b-form-group
                label="Tipo"
                label-for="filter_type"
              >
                <b-form-select
                  id="filter_type"
                  v-model="filtersValues.type"
                  :options="filtersOptions.type"
                />
              </b-form-group>
            </b-col>

            <b-col cols="3">
              <b-form-group
                label="Status"
                label-for="filter_status"
              >
                <b-form-select
                  id="filter_status"
                  v-model="filtersValues.status"
                  :options="filtersOptions.status"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="3"
              class="form-buttons"
            >
              <b-form-group
                label=""
                label-for="btn-buscar"
                style="margin-top: 20px"
              >
                <b-button
                  id="btn-buscar"
                  variant="primary"
                  @click="searchAndResetPage"
                >
                  Buscar
                </b-button>

                <b-button
                  id="btn-limpar"
                  variant="outline-secondary"
                  @click="reset()"
                >
                  Limpar
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-actions>

        <b-card>
          <b-row
            class="header-card"
            align-v="center"
          >
            <b-col>
              <h4>
                Resultados
              </h4>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <b-table
                :busy="isLoading"
                fixed
                :items="results"
                :fields="result_fields"
                sort-icon-left
                no-local-sorting
                :sort-by.sync="filtersValues.sortBy"
                :sort-desc.sync="filtersValues.sortDesc"
                @sort-changed="sortingChanged"
              >
                <template #table-busy>
                  <div class="text-center table-spinner">
                    <b-spinner label="Loading..." />
                    <strong>Carregando...</strong>
                  </div>
                </template>

                <template #cell(data)="row">
                  <span class="d-block"><strong>Nome: </strong>{{ row.item.name }}</span>
                  <span class="d-block"><strong>E-mail: </strong>{{ row.item.email }}</span>

                  <div v-if="row.item.deleted_at === null">
                    <strong>Status: </strong>{{ $t(`users.columns.status.${row.item.status}`) }} <span v-if="row.item.status !== 'basic' && row.item.status !== 'review'"><br>{{ row.item.plan_group_name }} {{ row.item.payment_interval ? ' - ' + $t(`financial.columns.payment_interval.${row.item.payment_interval}`) : '' }}</span>
                  </div>
                  <div v-else>
                    <strong>Status: </strong> <span class="text-danger">REMOVIDO</span>
                  </div>

                  <span class="d-block"><strong>Tipo: </strong> {{ $t(`commercia.users.columns.person_type.${row.item.person_type}`) }}</span>
                </template>

                <template #cell(phones)="row">
                  <div
                    v-if="row.item.phones"
                  >
                    <span
                      v-for="phone in row.item.phones"
                      :key="`phone-${phone}`"
                      class="multiple-lines"
                    >
                      {{ phone | phone }}
                    </span>
                  </div>
                </template>

                <template #cell(created_at)="row">
                  {{ row.item.created_at | date('D/MM/Y H:mm') }}
                </template>

                <template #cell(actions)="">
                  <div class="text-nowrap text-center">
                    N/A
                  </div>
                </template>

                <template #row-details="row">
                  <b-spinner v-if="!row.item.message" />
                  <p>{{ row.item.message }}</p>
                </template>

              </b-table>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="6">
              <p>Mostrando {{ pages.from }} até {{ pages.to }} de {{ pages.total }} resultados</p>
            </b-col>

            <b-col cols="6">
              <!-- pagination nav -->
              <b-pagination-nav
                v-model="currentPage"
                :number-of-pages="pages.last_page"
                :link-gen="() => { return '#' }"
                align="end"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>

    </b-row>

  </section>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import UserService from '@/services/userService'
import CommerciaService from '@/services/commerciaService'
import { formatDateTimeDbToView } from '@/utils/helpers'
// import moment from 'moment'
import {
  BRow,
  BCol,
  BTable,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BPaginationNav,
  BSpinner,
  BButton,
  // BModal,
  BCard,
  BBreadcrumb,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  userTypes, userStatus,
} from '@/utils/options'

export default {
  components: {
    BCardActions,
    BCard,
    BRow,
    BCol,
    BTable,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BPaginationNav,
    BSpinner,
    BButton,
    // BModal,
    BBreadcrumb,
    // vSelect,
  },

  data() {
    return {
      userService: null,
      isLoading: true,
      breadcrumb: [
        {
          text: 'Dashboard',
          to: { name: 'cms-dashboard' },
        },
        {
          text: 'Contas Commercia',
          active: true,
        },
      ],

      nameFilter: '',
      applyFilterIn: '',
      filtersInitialValues: {},
      filtersValues: {
        name_or_email: '',
        type: 'all',
        status: 'all',
        sortBy: 'id',
        sortDesc: true,
      },
      filtersOptions: {
        status: [
          { value: 'all', text: 'Todos' },
          ...userStatus,
        ],
        type: [
          { value: 'all', text: 'Todos' },
          ...userTypes,
        ],
      },

      pages: 1,
      currentPage: 1,
      results: [],
      result_fields: [],
      formatDateTimeDbToView,
    }
  },

  watch: {
    currentPage() {
      this.getUsers()
    },
  },

  created() {
    this.userService = new UserService()
    this.getUsers()
    this.filtersInitialValues = { ...this.filtersValues }

    this.result_fields = [
      { key: 'id', label: 'ID', sortable: true, thStyle: 'width: 100px' },
      { key: 'data', label: 'Dados', sortable: true },
      { key: 'phones', label: 'Telefones', sortable: true, thStyle: 'width: 150px' },
      { key: 'created_at', label: 'Criação', sortable: true, thStyle: 'width: 160px' },
    ]

    if (this.showActions()) {
      this.result_fields.push({ key: 'actions', label: 'Ações', sortable: false, thStyle: 'width: 150px' })
    }
  },

  methods: {
    reset() {
      this.filtersValues = JSON.parse(JSON.stringify(this.filtersInitialValues))
      this.getUsers()
    },

    searchAndResetPage() {
      if (this.currentPage !== 1) {
        this.currentPage = 1
      } else {
        this.getUsers()
      }
    },

    sortingChanged(ctx) {
      this.filtersValues.sortBy = ctx.sortBy
      this.filtersValues.sortDesc = ctx.sortDesc
      this.getUsers()
    },

    showActions() {
      return this.$can('read', 'Users') || this.$can('update', 'Users') || this.$can('delete', 'Users')
    },

    deleteUser(userId) {
      this.$swal({
        title: 'Deletar usuário?',
        text: 'Esta ação não poderá ser desfeita!',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sim, deletar!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isLoading = true
          this.userService.delete(userId).then(response => {
            this.isLoading = false
            if (response.status === 200) {
              this.results = this.results.filter(filter => filter.id !== userId)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Usuário ${userId} removido!`,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  // text: `!`,
                },
              })
            }
          })
        }
      }).catch(error => {
        this.isLoading = false
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Erro!',
            text: error.response.data.message,
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
      })
    },

    refreshNameFilter(e) {
      let applyFilterIn = ''
      const { value } = e.target
      if (value.replace(/[^0-9]/, '').length === value.length) {
        applyFilterIn = `${applyFilterIn}${applyFilterIn.length ? ', ' : ''} ID`
        if (value.length >= 6) {
          applyFilterIn = `${applyFilterIn}${applyFilterIn.length ? ', ' : ''} Telefone, CPF e CNPJ`
        }
      } else if (value.replace(/[@]/, '').length !== value.length) {
        applyFilterIn = `${applyFilterIn}${applyFilterIn.length ? ', ' : ''} E-mail`
      } else {
        applyFilterIn = `${applyFilterIn}${applyFilterIn.length ? ', ' : ''} Nome, E-mail`
      }

      this.applyFilterIn = `Pesquisa no campo: ${applyFilterIn}`
    },

    getUsers() {
      this.isLoading = true
      CommerciaService.list(this.filtersValues, this.currentPage).then(response => {
        if (response.data !== null) {
          this.results = response.data.data.users.data
          this.pages = response.data.data.users
          delete this.pages.data
        }
        this.isLoading = false
      })
    },

  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select';

  #user-list {
    .b-table{
      td{
        padding: 0.5rem 1rem;
      }
    }

    .multiple-lines{
      display:block;
    }

    .multiple-lines-email{
      margin-top: 5px;
      display:block;
    }

    .header-card {
      margin-bottom: 20px;
    }

    .form-buttons button {
      margin-right: 10px;
    }
  }
</style>
